// extracted by mini-css-extract-plugin
export var bgGreen = "StoryCard-module--bg--green--jFH6+";
export var borderGreen = "StoryCard-module--border--green--b4nGc";
export var colorGreen = "StoryCard-module--color--green--3zSRI";
export var bgDarkGreen = "StoryCard-module--bg--dark-green--Izxkp";
export var borderDarkGreen = "StoryCard-module--border--dark-green--PwVM-";
export var colorDarkGreen = "StoryCard-module--color--dark-green--2JeiL";
export var bgGray = "StoryCard-module--bg--gray--u2JFR";
export var borderGray = "StoryCard-module--border--gray----DYl";
export var colorGray = "StoryCard-module--color--gray--qpJOa";
export var bgWhite = "StoryCard-module--bg--white--vwbYg";
export var borderWhite = "StoryCard-module--border--white--PFhA8";
export var colorWhite = "StoryCard-module--color--white--5xOpw";
export var bgBlack = "StoryCard-module--bg--black--BUxFQ";
export var borderBlack = "StoryCard-module--border--black--C3SXI";
export var colorBlack = "StoryCard-module--color--black--erQzG";
export var bgSilver = "StoryCard-module--bg--silver---5gXn";
export var borderSilver = "StoryCard-module--border--silver--AJgob";
export var colorSilver = "StoryCard-module--color--silver--xehnz";
export var bgYellow = "StoryCard-module--bg--yellow--X6z6r";
export var borderYellow = "StoryCard-module--border--yellow--wNTTr";
export var colorYellow = "StoryCard-module--color--yellow--75zAx";
export var headingH1 = "StoryCard-module--heading--h1--ffpGC";
export var headingH2 = "StoryCard-module--heading--h2--1ZI9B";
export var headingH3 = "StoryCard-module--heading--h3--pi4pX";
export var headingLabel = "StoryCard-module--heading--label--cgm5x";
export var headingText = "StoryCard-module--heading--text--G9E0h";
export var headingPoppinsBlackRichH4 = "StoryCard-module--headingPoppinsBlack--richH4--L74rQ";
export var headingBoldH4 = "StoryCard-module--headingBold--h4--7s8mD";
export var titleH4 = "StoryCard-module--title--h4--m0WuR";
export var titleP = "StoryCard-module--title--p--2xSsG";
export var body = "StoryCard-module--body--ebbfu";
export var bodyBodysmall = "StoryCard-module--body--bodysmall--f66FW";
export var bodyHeroText = "StoryCard-module--body--heroText--1D++S";
export var bodyText = "StoryCard-module--body--text--fuskW";
export var bodyRegularBody = "StoryCard-module--bodyRegular--body--lcU5s";
export var bodyRegularBodysmall = "StoryCard-module--bodyRegular--bodysmall--s3bfp";
export var bodyRegularHeroText = "StoryCard-module--bodyRegular--heroText--0Dspy";
export var bodyRegularText = "StoryCard-module--bodyRegular--text--PeMJn";
export var cardtitle = "StoryCard-module--cardtitle--z1hdV";
export var cardsingle = "StoryCard-module--cardsingle--rRsd1";
export var mdBlock = "StoryCard-module--md-block--QnNWJ";
export var iconwrapper = "StoryCard-module--iconwrapper--Xeanc";