import React from "react"
import { Link } from "gatsby"
//import PropTypes from "prop-types"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { cardsingle, cardtitle, iconwrapper } from "./StoryCard.module.scss"
import "./StoryCard.scss"
const StoryCard = ({ data }) => {
  const cardIcon = getImage(data.story.document.data.story_image.localFile.card)
  return (
    <div
      className={`col ${cardsingle} col-12 col-md-6 col-lg-4 ps-lg-3 pe-lg-5`}
    >
      <div className="h-100">
        <div className={iconwrapper}>
          <GatsbyImage
            image={cardIcon}
            alt={
              data.story.document.data.story_image.alt
                ? data.story.document.data.story_image.alt
                : ""
            }
          />
        </div>
        <div className="card-body px-0">
          <h3 className={`${cardtitle} card-title`}>
            {data.story.document.data.full_name}
          </h3>
          <p className="card-text">
            <b>Age : </b>
            {data.story.document.data.age}
          </p>
          <p className="card-text">
            <b>Occupation : </b>
            {data.story.document.data.occupation}
          </p>
          <p className="card-text">{data.story.document.data.summary.text}</p>

          <Link
            to={`/why-it-matters/${data.story.document.uid}`}
            className="link"
          >{`Read ${data.story.document.data.full_name}'s story `}
          <StaticImage src="./../../images/arrow-right.png" alt="" className="arrow-right" /></Link>
        </div>
      </div>
    </div>
  )
}

export default StoryCard
