import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layouts/layout"
import Seo from "../../components/global/Seo/seo"
import HeroBanner from "../../components/HeroBanner/HeroBanner"
import StoryCard from "../../components/StoryCard/StoryCard"

export const whyitmattersQuery = graphql`
  query whyitmattersQuery {
    prismicCvswapoutStoryList {
      id
      href
      data {
        seo_metadata {
          document {
            ...seoData
          }
        }
        hero_banner {
          document {
            ...heroBannerData
          }
        }
        name
        title
        stories {
          story {
            document {
              ... on PrismicCvswapoutStoryDetails {
                id
                uid
                data {
                  story_image {
                    alt
                    localFile {
                      ...storyCardImage
                    }
                  }
                  name
                  full_name
                  age
                  occupation
                  summary {
                    richText
                    html
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// markup
const WhyItMatters = ({ data }) => {
  if (!data.prismicCvswapoutStoryList?.data) return null

  let StoryCards = data.prismicCvswapoutStoryList.data.stories.map(
    (story, index) => {
      return <StoryCard data={story} key={index} />
    }
  )

  return (
    <Layout>
      <Seo
        data={data.prismicCvswapoutStoryList.data.seo_metadata.document.data}
      />
      <HeroBanner
        data={data.prismicCvswapoutStoryList.data.hero_banner.document.data}
      />
      <section className="article-listing">
        <div className="article-listing--bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="page-heading">
                  {data.prismicCvswapoutStoryList.data.title}
                </h2>
              </div>
            </div>
            <div className="row">{StoryCards}</div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default WhyItMatters
